import tw, { styled, theme } from "twin.macro"

export const FormWrapper = tw.form`relative`

export const InputButton = styled.div`
	${tw`relative flex flex-wrap mt-16`};

	> svg {
		${tw`hidden md:block absolute top-1/2 transform -translate-y-1/2 left-5 text-asphalt`};
	}

	input,
	button {
		${tw`py-3 sm:text-lg md:text-xl font-medium focus:rounded-none`};
	}

	input {
		${tw`pl-4 md:pl-14 pr-3 text-sanjuan border border-sanjuan rounded-l-full flex-1 shadow hover:shadow-lg`};
	}

	button {
		svg {
			${tw`md:hidden`};
		}

		span {
			${tw`hidden md:block`};
		}

		${tw`px-6 md:px-9 bg-sunset text-white inline-flex items-center border border-sunset rounded-r-full hover:bg-watermelon focus:bg-watermelon`};
	}
`

export const ExtraButtons = styled.div`
	${tw`mt-2 pl-6 md:pl-12`};

	label {
		${tw`inline-block m-1 md:mx-2`};
	}

	input {
		${tw`sr-only`};

		&:focus + span {
			${tw`border-catskill`}
		}
	}

	span {
		box-shadow: 0 1px 0 0 ${theme`colors.catskill`};

		${tw`text-solitude border border-transparent cursor-pointer hover:text-sunset`};
	}
`

export const States = styled.div`
	${tw`absolute bottom-full left-8 md:left-16 -mx-2 md:-mx-4 mb-2`};

	.state {
		${tw`inline-block py-1.5 px-3 text-granny leading-none font-medium rounded-full shadow-none hover:(bg-granny text-sanjuan)`};
	}

	input:checked + span {
		${tw`bg-granny text-sanjuan`};
	}
`
