import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import tw, { styled } from "twin.macro"

import SearchForm from "../components/search/index"
import SVG from "../components/svg"

const Wrapper = styled.div`
	${tw`relative z-1 py-10 md:py-14 xl:py-28 bg-asphalt text-white`}
	.address {
		${tw`text-granny`}
	}

	.title {
		${tw`text-sunset`}
	}
`

const Search = ({ headingText }) => {
	const [state, setState] = React.useState({
		active: "address",
	})

	const SVGIcon = active => {
		setState(state => ({
			...state,
			active,
		}))
	}

	const {
		file: {
			childImageSharp: { fluid: ImageSrc },
		},
	} = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "sabrina-mazzeo--wADGKIcBwM-unsplash.png" }) {
				childImageSharp {
					fluid(maxWidth: 722) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<Wrapper id="property-search">
			<div className="container">
				<h1 tw="relative mt-4 md:mt-8" style={{ maxWidth: "444px" }}>
					{parse(headingText)}

					<SVG
						width="34"
						height="43"
						id="double-down"
						tw="absolute top-full left-0 mt-4"
						className={state.active}
					/>
				</h1>

				<div tw="pt-px px-4 md:px-0" style={{ maxWidth: "888px" }}>
					<SearchForm update={SVGIcon} />
				</div>
			</div>

			<div
				className="container"
				tw="absolute inset-0 -z-1 overflow-hidden xl:overflow-visible"
				style={{ maxWidth: "1920px" }}
			>
				<div
					tw="absolute top-0 -right-4 xl:-right-24 object-cover opacity-60 xl:opacity-100"
					style={{
						width: "722px",
						height: "814px",
					}}
				>
					<Image fluid={ImageSrc} alt="Check That Property" critical />
				</div>
			</div>
		</Wrapper>
	)
}

export default Search
