import React from "react"

export const script = (src, id, callback = null) => {
	const existing = document.getElementById(id)

	if (!existing) {
		const script = document.createElement("script")
		script.async = true
		script.src = src
		script.id = id
		document.body.appendChild(script)

		script.onload = () => {
			if (callback) {
				callback()
			}
		}
	}

	if (existing && callback) {
		callback()
	}

	return () => {
		if (existing && callback) {
			existing.remove()
		}
	}
}

export const useScript = (src, id, callback = null) => {
	React.useEffect(() => script(src, id, callback), [src, id, callback])
}
