import React from "react"
import axios from "axios"
import qs from "qs"

import { navigate } from "gatsby"
import { ExtraButtons, FormWrapper, InputButton, States } from "./wrappers"

import { script } from "../../../utilities/use-script"
import { localStorageGetItem } from "../../../utilities/storage"
import SVG from "../svg"

class Search extends React.Component {
	constructor(props) {
		super(props)

		this.address_input = React.createRef()
		this.title_input = React.createRef()
		this.type_input = React.createRef()
		this.default = {
			type: props.default?.type ? props.default.type : "address",
			state: props.default?.state ? props.default.state : "NSW",
		}
		this.requestServer = props.trigger
		this.updateSVG = props.update
		this.texts = {
			address: {
				placeholder: !props?.default ? "Search by property address" : "Search a new property by address",
				button: !props?.default ? "Search Address" : "Search Again",
				helper: "Don't know the address but know the Title Reference?",
			},
			title: {
				placeholder: !props?.default ? "Search by" : "Search a new",
				button: !props?.default ? "Search Title" : "Search Again",
				helper: "Don't know the Title Reference but know the address?",
				state: {
					NSW: "Title Reference (eg. 1863/1000001)",
					QLD: "Lot/Plan (eg. 8/RP601844)",
					VIC: "Volume/Folio (eg. 8555/407)",
					SA: "Volume/Folio (eg. 5359/705)",
					WA: "Volume/Folio (eg. 2100/341)",
				},
			},
		}

		this.state = {
			active: this.default.type,
			placeholder: this.texts.title.placeholder + " " + this.texts.title.state[this.default.state],
			button: this.texts[this.default.type].button,
			helper: this.texts[this.default.type].helper,
			metadata: {
				address: {
					afd: {},
				},
				title: {
					state: this.default.state,
				},
			},
		}
		this.state_names = ["NSW", "VIC", "QLD", "SA"]
		this.getActiveStates = this.getActiveStates.bind(this)
		this.handleStates = this.handleStates.bind(this)
		this.toggleType = this.toggleType.bind(this)
		this.widget = null
	}

	toggleType() {
		let active = this.state.active

		if ("title" === active) {
			active = "address"
		} else {
			active = "title"
		}

		if (this.updateSVG) {
			this.updateSVG(active)
		}

		this.setState({
			active,
			placeholder: this.texts.title.placeholder + " " + this.texts.title.state[this.state.metadata.title.state],
			button: this.texts[active].button,
			helper: this.texts[active].helper,
			metadata: this.state.metadata,
		})
	}

	getActiveStates() {
		const request = {
			method: "GET",
			url: 'https://cms.checkthatproperty.com.au/api',
			params: {
				action: "active_states",
			},
			responseType: "json",
		}

		return axios(request)
			.then(response => {
				if (response.data.success) {
					this.state_names = response.data.data.sort(
						(a, b) => this.state_names.indexOf(a) - this.state_names.indexOf(b)
					)
				}
			})
			.catch(error => alert(JSON.stringify(error.response, null, 4)))
	}

	componentDidMount() {
		const result = this.getActiveStates()
		const client = JSON.parse(localStorageGetItem("client")) || {
			ip: "127.0.0.1",
			state: "undefined",
			mapped: "undefined",
		}

		result.then(() => {
			if (
				!this.props.default?.state &&
				"undefined" !== client.mapped &&
				this.state_names.includes(client.mapped)
			) {
				const state = this.state

				state.metadata.title.state = client.mapped

				this.setState(state)
			}
		})

		script("https://api.addressfinder.io/assets/v3/widget.js", "addressfinder_widget", () =>
			result.then(this.loadWidget)
		)
	}

	componentWillUnmount() {
		this.widget.destroy()
		this.widget = null
	}

	loadWidget = () => {
		this.widget = new AddressFinder.Widget( // eslint-disable-line no-undef
			document.getElementById("address_input"),
			process.env.GATSBY_AF_KEY,
			"AU",
			{
				address_metadata_params: {
					gps: "1",
				},
				address_params: {
					gnaf: "1",
					state_codes: this.state_names.join(","),
				},
				empty_content:
					"No addresses were found. This could be a new address, or you may need to check the spelling. Learn more",
			}
		)

		this.widget.on("address:select", (fullAddress, metadata) => {
			const state = this.state

			state.metadata.address.state = metadata.state_territory
			state.metadata.address.afd = metadata

			this.setState(state)
		})
	}

	handleStates(e) {
		const state = this.state

		state.metadata.title.state = e.currentTarget.value

		state.placeholder = this.texts.title.placeholder + " " + this.texts.title.state[this.state.metadata.title.state]

		this.setState(state)
	}

	preparedMetadata() {
		const active = this.state.active

		return {
			...this.state.metadata[active],
			q: this[`${active}_input`].current.value,
			type: active,
		}
	}

	render = () => (
		<FormWrapper
			target="_parent"
			method="GET"
			action="/search"
			onSubmit={event => {
				if (!!this.props.embed) {
					return
				}

				event.preventDefault()

				const metadata = this.preparedMetadata()

				if (this.requestServer && metadata.q.trim()) {
					this.requestServer(metadata)
				}

				const query = { ...metadata }

				delete query["afd"]

				navigate(`/search/?${qs.stringify(query)}`, {
					state: { metadata: metadata },
				})

				this.address_input.current.value = ""
				this.title_input.current.value = ""
			}}
		>
			<InputButton>
				<SVG width="20" height="22" id="search" />

				<input
					id="address_input"
					type="search"
					hidden={"address" !== this.state.active}
					disabled={"address" !== this.state.active}
					name="q"
					placeholder={this.texts.address.placeholder}
					ref={this.address_input}
				/>

				<input
					type="search"
					hidden={"title" !== this.state.active}
					disabled={"title" !== this.state.active}
					name="q"
					placeholder={this.state.placeholder}
					ref={this.title_input}
				/>

				<button type="submit">
					<SVG width="20" height="22" id="search" aria-label={this.state.button} />
					<span>{this.state.button}</span>
				</button>
			</InputButton>

			<ExtraButtons>
				<label>
					<input
						type="checkbox"
						checked
						onChange={this.toggleType}
						name="type"
						value={this.state.active}
						ref={this.type_input}
					/>
					<span>{this.state.helper}</span>
				</label>

				{"title" === this.state.active && (
					<States>
						{this.state_names.map((state, index) => (
							<label key={index}>
								<input
									id={`state_${state}`}
									type="radio"
									name="state"
									value={state}
									onChange={this.handleStates}
									defaultChecked={state === this.state.metadata.title.state}
								/>

								<span className="state">{state}</span>
							</label>
						))}
					</States>
				)}
			</ExtraButtons>
		</FormWrapper>
	)
}

export default Search
