import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import tw from "twin.macro"

import Layout from "../layouts/base"
import SEO from "../components/seo"
import Search from "../partials/search"

const Report = loadable(() => import("../partials/report"))
const Testimonials = loadable(() => import("../partials/testimonials"))
const FAQs = loadable(() => import("../partials/faqs"))
const Articles = loadable(() => import("../partials/articles"))
const CTA = loadable(() => import("../partials/cta"))
const Advantage = loadable(() => import("../partials/advantage"))
const Wrapper = tw.div``

const FrontPageTemplate = ({
	data: {
		page,
		wp: { frontPage: data },
		allWpTestimonial: { nodes: testimonials },
		allWpFaq: { nodes: faqs },
		allWpPost: { nodes: articles },
	},
	pageContext: { blogUri, specific },
}) => {
	const pageTitle = [specific, page.seo?.metaTitle ?? page.title].join(" ")
	let pageDescription = page.seo.metaDescription
	let searchHeadingText = data.headings.search

	if (!!specific) {
		pageDescription = pageDescription?.replace("Australian", specific)
		searchHeadingText = searchHeadingText?.replace("Australian", specific)
	}

	return (
		<Layout>
			<SEO title={pageTitle} description={pageDescription} />

			<main tw="overflow-hidden">
				<Search headingText={searchHeadingText} />

				<Report steps={data.ordering.steps} data={data.reports} />

				{!!testimonials.length && <Testimonials title={data.headings.testimonials} items={testimonials} />}

				{!!faqs.length && <FAQs title={data.headings.faqs} items={faqs} />}

				{!!articles.length && <Articles title={data.headings.articles} items={articles} blogUri={blogUri} />}

				<CTA heading={data.cta.heading} button={data.cta.button} />
				<Advantage />
			</main>
		</Layout>
	)
}

export default FrontPageTemplate

export const pageQuery = graphql`
	query FrontPage(
		# these variables are passed in via createPage.pageContext in gatsby-node.js
		$id: String!
	) {
		# selecting the current page by id
		page: wpPage(id: { eq: $id }) {
			content
			title
			seo {
				metaTitle
				metaDescription
			}
		}
		allWpTestimonial(limit: 3) {
			nodes {
				id
				title
				content
			}
		}
		allWpFaq {
			nodes {
				id
				title
				content
			}
		}
		wp {
			frontPage {
				headings {
					search
					faqs
					testimonials
					articles
				}
				ordering {
					steps {
						title
						description
					}
				}
				reports {
					image {
						localFile {
							childImageSharp {
								fluid(maxWidth: 593) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
						altText
						caption
						description
						title
					}
					content {
						heading
						items {
							title
							description
							link {
								text
								type
							}
						}
					}
				}
				cta {
					heading
					button
				}
			}
		}
		allWpPost(limit: 4) {
			nodes {
				uri
				title
				excerpt
				featuredImage {
					node {
						localFile {
							childImageSharp {
								fluid(maxWidth: 568) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
			}
		}
	}
`
